import React from "react";
import "./Offer.css";
import kp1 from '../../images/cloud.gif';
import kp2 from '../../images/languge.gif';
import kp3 from '../../images/frame.gif';
import kp4 from '../../images/data1.gif';
import kp5 from '../../images/security.gif';
import kp6 from '../../images/crm.gif';
import { Element } from "react-scroll";
import { scrollToTop } from "../../Utils/Scroll&Animations";
import { Link as LinkRouter } from "react-router-dom";
export default function Offer(props) {
  const cards = (
    <div className="offer__cards">
      <div data-aos="fade-up" className="card card--offer">
        <div className="card__img-wrapper">
        <img src={kp1} className="card__img" alt="kp1" />
        </div>
        <div className="card__text">
          <h4 className="card__text-main">Cloud Providers</h4>
          <ul className="card__list">
            <li className="card__list-item">Amazon Web Service (AWS)</li>
            <li className="card__list-item">Google Cloud Platform</li>
            <li className="card__list-item">Adobe Creative Cloud</li>
            <li className="card__list-item">Oracle Cloud</li>
            <li className="card__list-item">SAP</li>
          </ul>
        </div>
      </div>
      <div data-aos="fade-up" className="card card--offer">
      <img src={kp2} className="card__img" alt="kp2" />
        <div className="card__text">
          <h4 className="card__text-main">Programming Languages</h4>
          <ul className="card__list">
            <li className="card__list-item">
             PHP
            </li>
            <li className="card__list-item">
             Python
            </li>
            <li className="card__list-item">React</li>
            <li className="card__list-item">
              Javascript
            </li>
            <li className="card__list-item">
              .Net
            </li>
          </ul>
        </div>
      </div>
      <div data-aos="fade-up" className="card card--offer">
      <img src={kp3} className="card__img" alt="kp3" />
        <div className="card__text">
          <h4 className="card__text-main">Frameworks</h4>
          <ul className="card__list">
            <li className="card__list-item">
            Ruby on Rails
            </li>
            <li className="card__list-item">
            Django
            </li>
            <li className="card__list-item">
            Angular(Also, know as Angular JS)
            </li>
            <li className="card__list-item">
            ASP.NET
            </li>
            <li className="card__list-item">
            METEOR
            </li>
          </ul>
        </div>
      </div>
      <div data-aos="fade-up" className="card card--offer">
      <img src={kp4} className="card__img" alt="kp4" />
        <div className="card__text">
          <h4 className="card__text-main">Database</h4>
          <ul className="card__list">
            <li className="card__list-item">
            Easily transferrable from one hosting account to another
            </li>
            <li className="card__list-item">
            Ability to add a shopping cart
            </li>
            <li className="card__list-item">
            Create multiple user profiles with different access levels
            </li>
            <li className="card__list-item">
            Ability to provide advanced search functions for visitors
            </li>
            <li className="card__list-item">
            Easily customize the look and function with plugins or extensions
            </li>
          </ul>
        </div>
      </div>
      <div data-aos="fade-up" className="card card--offer">
      <img src={kp5} className="card__img" alt="kp5" />
        <div className="card__text">
          <h4 className="card__text-main">Security</h4>
          <ul className="card__list">
            <li className="card__list-item">
            Regardless of the size of your business, developing a security framework can help reduce your overall risk.
            </li>
            <li className="card__list-item">
            The US National Institute of Standards and Technology (NIST) developed The Cybersecurity Framework which forms the basis of our website security principles framework in this guide.
            </li>
            <li className="card__list-item">
            Knowing security is a continuous process means it starting with the foundation of a website security framework. This framework will involve creating a “culture of security” where scheduled audits will help in keeping things simple and timely.
            </li>
            <li className="card__list-item">
            The five functions: Identify, Protect, Detect, Respond and Recover will be broken out in more detail along with actions to be applied.
            </li>
          </ul>
        </div>
      </div>
      <div data-aos="fade-up" className="card card--offer">
      <img src={kp6} className="card__img" alt="kp6" />
        <div className="card__text">
          <h4 className="card__text-main">CRM</h4>
          <ul className="card__list">
            <li className="card__list-item">
            Integration to your back-office accounting system
            </li>
            <li className="card__list-item">
            Open, industry-standard technology
            </li>
            <li className="card__list-item">
            Seamless flow of information between corporate systems and remote employees
            </li>
            <li className="card__list-item">Contact and campaign management</li>
            <li className="card__list-item">Multiple language and multicurrency capabilities to support global business</li>
          </ul>
        </div>
      </div>
    </div>
  );
  return (
    <Element name="offer">
      <section className="offer container">
        <div data-aos="fade-right" className="offer__text">
          <h2 className="offer__text-main">OUR OFFER</h2>
          <p className="offer__text-paragraph">
          Content marketing is a strategic marketing approach focused on creating and distributing valuable, relevant, and consistent content to attract and retain a clearly defined audience — and, ultimately, to drive profitable customer action.
          </p>
        </div>
        {cards}
      </section>
      
      <div data-aos="fade-down" className="job job--offer">
        <div className="container">
          <div className="job__text">
            <h3 className="job__text-main">
              Didn't find the service you're interested in?
            </h3>
            <p className="job__text-paragraph">See our full offer</p>
          </div>
          <LinkRouter
            onClick={() => setTimeout(() => scrollToTop(), 100)}
            to="/Offer"
          >
            <a className="job__button" href="#">
              <div>Learn more</div>
            </a>
          </LinkRouter>
        </div>
      </div>
    </Element>
  );
}
import React, { Component } from "react";
import "./index.css"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "aos/dist/aos.css";
import IntroOverlay from "../src/introOverlay";
import Menu from "./Components/Menu/Menu";
import MenuIndicators from "./Components/MenuIndicators/MenuIndicators.js";
import Header from "./Components/Header/Header";
import Offer from "./Components/Offer/Offer";
import About from "./Components/About/About";
import Job from "./Components/Aplication/Aplication";
import Realizations from "./Components/Realizations/Realizations";
import Contact from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
import Gallery from "./Components/Gallery/Gallery";
import OfferDetails from "./Components/OfferDetails/OfferDetails";
import Page404 from "./Components/Page404/Page404";
import { SemipolarLoading } from "react-loadingg";
import { HoopSpinner } from "react-spinners-kit";
import Loader from "./Components/Loader";
import gsap from "gsap";
import CookieConsent, { Cookies } from "react-cookie-consent";
import {
  configureScrollSettings,
  configureAnimationSettings,
  removeEvents,
  loadCallbackAnimation,
} from "./Utils/Scroll&Animations";

let tl = gsap.timeline();

const appAnimation = completeAnimation => {
  tl.from(".line span", 1.8, {
    y: 100,
    ease: "power4.out",
    delay: 1,
    skewY: 7,
    stagger: {
      amount: 0.3
    }
  })
    .to(".overlay-top", 1.6, {
      height: 0,
      ease: "expo.inOut",
      stagger: 0.4
    })
    .to(".overlay-bottom", 1.6, {
      width: 0,
      ease: "expo.inOut",
      delay: -0.8,
      stagger: {
        amount: 0.4
      }
    })
    .to(".intro-overlay", 0, {
      css: { display: "none" }
    })
    .from(".case-image img", 1.6, {
      scale: 1.4,
      ease: "expo.inOut",
      delay: -2,
      stagger: {
        amount: 0.4
      },
      onComplete: completeAnimation
    });
};

class App extends Component {
  state = { isLoaded: false };

  componentDidMount() {
    window.addEventListener("load", () => {
      configureAnimationSettings();
      configureScrollSettings();
      loadCallbackAnimation(() => {
        this.setState({ isLoaded: true });
      });
    });
  }

  componentWillUnmount() {
    removeEvents();
  }



  render() {
    Cookies.set("test", "nice"); 

    return (
      <Router>
        {!this.state.isLoaded && (
          <div className="loading-screen">
            <Loader />
          </div>
        )}
        
        <Menu />
        <Switch>
          <Route exact path="/">
            <IntroOverlay />
            <MenuIndicators />
            <Header />
            <Offer />
            <About />
            <Realizations />
            
            <Contact />
          </Route>
          <Route exact path="/Apply" render={() => <Job />} />
          <Route exact path="/Gallery" render={Gallery} />
          <Route exact path="/Offer" render={OfferDetails} />
          <Route path="*" render={Page404} />
        </Switch>
             
        <Footer />
        <CookieConsent
         
          onAccept={() => {
            alert("Yes!");
          }}
          debug={true}
          enableDeclineButton
          declineButtonText="Decline (optional)"
          onDecline={() => {
            alert("No!");
          }}
        >
          This website uses cookies to enhance the user experience.{" "}
          <span style={{ fontSize: "10px" }}>
            
          </span>
        </CookieConsent>
      </Router>
      
    );
  }
}

export default App;
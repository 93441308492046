import React, { Component } from "react";
import "./Aplication.css";
import emailjs from "emailjs-com";
import ButtonUI from "../Contact/Button.js";
import { Element } from "react-scroll";
import Recaptcha from 'react-google-invisible-recaptcha';
import URI from "../../Utils/URI.JS";
export default class Contact extends Component {
  state = {
    name: "",
    surname: "",
    tel: "",
    email: "",
    message: "",
  };

  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };

  sendMessage = () => {
    this.recaptcha.execute();
  }

  handleBtn = async (getAns) => {
    const { name, surname, tel, email, message } = this.state;

    if (!name || !surname || !tel || !email || !message)
      return getAns("Fill in all fields");

    var template_params = {
      name,
      surname,
      tel,
      email,
      message,
    };

    var service_id = URI.SERVICE_ID;
    var template_id = URI.TEMPLATE_ID;
    var user_id = URI.USER_ID;
    emailjs.send(service_id, template_id, template_params, user_id).then(
      (result) => {
        getAns(result.text);
        return result.text;
      },
      (error) => {
        getAns(error.text);
        return error.text;
      }
    );
  };

  render() {
    const { name, surname, tel, email, message } = this.state;

    return (
      <>
        <Element name="aplication">
          <div className="menubgc"></div>

          <section className="aplication">
            <div data-aos="fade-right" className="aplication__text">
              <h2>Career</h2>
              <h4>We offer our employees:</h4>
              <ul>
                <li>training and professional development opportunities</li>
                <li>employment contract</li>
                <li>online service</li>
                <li>work</li>
                <li>
                  needs of the employee
                </li>
                <li>accommodation in Bangalore for foreigners</li>
              </ul>
              <h4>Expect:</h4>
              <ul>
                <li>Punctuality</li>
                <li>Liability</li>
                <li>logical thinking</li>
                <li>conscientiousness and willingness to work</li>
                <li>willingness to develop professional skills</li>
                <li>lack of alcohol addiction</li>
                <li>ability to work in a team</li>
                <li>taking care  entrusted to you</li>
              </ul>
              <div className="aplication__form">
                <h2>APPLY!</h2>
                <ul>
                <li>
                Please send your resumes to hiring@repletech.com
                </li>
                </ul>
              </div>
            </div>

            <div className="aplication__img"></div>
          </section>
         
        </Element>
      </>
    );
  }
}
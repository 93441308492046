import React, { Component } from "react";
import "./OfferDetails.css";
import { Element } from "react-scroll";

const offer = [
  {
    id: 1,
    url: "https://wallpapercave.com/wp/wp3271047.png",
    mainText:
      "Outsourced Product Development ",
    text: "We are dedicated to help you build the application and technologies that meet your business aspirations.👉 We know the latest versions and libraries and can integrate technologies to satisfy your expectations.👉 Repletech works as your outsourced product development partner and provides end-to-end product development lifecycle management.👉 We brainstorm, design, develop, test and maintain your product as your extended IT team. Our agile methodologies are ideally suitable for the iterative development and better product-market-fit. ",
  },
  {
    id: 2,
    url:
      "https://spectralogic.com/wp-content/uploads/AdobeStock_99220645.jpeg",
    mainText: "Mobile app development",
    text: "We work with you as your advisors and ensure that you get it right in terms of the content management system, site architecture, branding, styling, design, content, and integration with marketing and other applications.👉 We help you put together all the digital assets as per your digital strategy, and maintain the same over time. ",
  },
  {
    id: 3,
    url: "https://wallpapertag.com/wallpaper/full/0/4/3/679239-free-download-wallpaper-futuristic-technology-1920x1200-for-windows.jpg",
    mainText: "Legacy Application Migration",
    text: "We migrate your old software to the new digital technology stack to ensure interoperability, scalability and extendibility in the fast-evolving technology landscape.👉We ensure that the software gains from the competitive advantages brought in by cloud, mobile, social and analytics. In short, we re-engineer the software for competitive advantage while maintaining the legacy compatibility. ",
  },
  {
    id: 4,
    url:
      "https://wallpapercave.com/wp/wp3147504.jpg",
    mainText: "API Development",
    text: "In a connected world, it is more critical than ever that applications talk to each other.👉jThis allows to control redundancy, re-work and manual interchange between systems.👉We build strong, secure, scalable Application Programming Interface (API) to power the world of Service Oriented Architecture (SOA) to tap the unlimited world of opportunities which the connected application brings forth with it. ",
  },
  {
    id: 5,
    url: "https://securecdn.pymnts.com/wp-content/uploads/2017/11/cybersecurity.jpg",
    mainText: "Cyber security",
    text: "Sameer to input these from ❗ https://cybersecurityhive.com/services/  👉  There are varying types of models which try to ascertain Cybersecurity Risk.  There is no uniform model yet adopted by the industry, therefore, a selection of models will be examined.  From here, how it can be used by the insurance companies and potential policy holders will be further examined.  At the present time, the insurance industry has no means by which they can quantify the level of risk that is tolerable to them as they generate new policies to potential policyholders. ",
  },
  {
    id: 6,
    url:
      "https://image.freepik.com/free-photo/modern-futuristic-white-square-round-cube-boxes-stack-wall-floor_25920-1635.jpg",
    mainText: "Cloud Infrastructure",
    text: "Cloud infrastructure refers to the hardware and software components -- such as servers, storage, a network, virtualization software, services and management tools -- that support the computing requirements of a cloud computing model.👉Cloud infrastructure also includes an abstraction layer that virtualizes and logically presents resources and services to users through application program interfaces and API-enabled command-line or graphical interfaces.👉In cloud computing, these virtualized resources are hosted by a service provider or IT department and are delivered to users over a network or the internet.👉These resources include virtual machines and components, such as servers, memory, network switches, firewalls, load balancers and storage",
  },
  {
    id: 7,
    url:
      "https://robots.net/wp-content/uploads/2019/12/featured-1.jpg",
    mainText: "Technology Stack",
    text: "A technology stack is a crucial part of developing any web or mobile application.👉People refer to the term when speaking of the combination of programming languages and software underneath a development project in question.👉Picking the right combination of underlying development tools is very important in the early stages of a project.👉When building a skyscraper, you don’t start with the marble facade or the fountain in the lobby.👉You start with a deep foundation and girders to hang everything else on. Your tech stack is like this skeleton and you should consider hiring a cloud architect to help ensure your stack does not fall apart.👉A tech stack is the underlying elements of a web or mobile application. These are the frameworks, languages, and software products that everything else is built on. For example, you might have created your web application with Ruby on Rails—that’s the language and framework. That might access a database created with PostgreSQL. You’ll need to host that on a server, say, an Apache server. You’ll need Phusion Passenger to make that happen. Those are all elements of the server-side stack.👉The client-side tech stack includes HTML, CSS, and JavaScript.👉That’s what translates your application to a readable format for the user’s browser.👉If you’ve created a mobile application, the stack is very small: it’s usually only a native application, created with something like Xcode or Android Studio.",
  },
  {
    id: 8,
    url:
      "https://i.pinimg.com/originals/6c/17/5a/6c175a43f16bdd7c7bbae1a958f2b0d5.jpg",
    mainText: "ADVANTAGES :",
    text: "",
  },
];

export default function OfferDetails() {
  return (
    <>
      <Element name="aptext1cation">
        <div className="menubgc"></div>
        <div
          data-aos="fade-up"
          className="offer__text"
          style={{ margin: "5vh auto 10vh", padding: "0 5vw" }}
        >
          <h2 className="offer__text-main">OUR CORE SERVICES</h2>
          <p className="offer__text-paragraph">
          Optimizing operations and utilizing new technologies is critical for any business. Our Core Business Operations team supports the heart of your business through transformation of existing technology platforms to drive innovation, improve financial performance, and accelerate speed to market.
          </p>
        </div>
        {offer.map(({ id, url, mainText, text, text1 }) => (
          <section className="offer-details">
            <div
              data-aos="fade-up"
              className={`offer-details__text ${
                id % 2 !== 0 ? "left" : "right"
              }`}
            >
              <h2 className="offer-details__main-text">{mainText}</h2>
              <h7 className="offer-details__text">{text}</h7>
              <h5 className="offer-details__text1">{text1}</h5>
            </div>
            <div
              style={{ backgroundImage: `url(${url})` }}
              className={`offer-details__img ${
                id % 2 === 0 ? "left" : "right"
              }`}
            />
          </section>
        ))}
      </Element>
    </>
  );
}
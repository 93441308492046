import React, { Component } from "react";
import emailjs from "emailjs-com";
import ButtonUI from "./Button.js";
import { Icon } from "@iconify/react";
import locationPin from "@iconify/icons-entypo/location-pin";
import "./Contact.css";
import { Link as LinkRouter } from "react-router-dom";
import { Element } from "react-scroll";
import { scrollToTop } from "../../Utils/Scroll&Animations";
import Recaptcha from 'react-google-invisible-recaptcha';
import kp1 from '../../images/cloud.gif';

export default class Contact extends Component {
  state = {
    name: "",
   
    tel: "",
    email: "",
    message: "",
  };

  handleChange = (e, name) => {
    this.setState({
      [name]: e.target.value,
    });
  };

  handleBtn = async (getAns) => {
    const { name,  tel, email, message } = this.state;

    console.log(this.state);
    ////////////Validation///////////
    if (!name ||  !tel || !email || !message)
      return getAns("Fill  all fields");

    ////////////
    var template_params = {
      name,
      tel,
      email,
      message,
    };

    var service_id = "service_iwt156y";
    var template_id = "template_6m7ri3e";
    var user_id = "user_1RKEQx50VmQtw8TVOIN0s";
    emailjs.send(service_id, template_id, template_params, user_id).then(
      (result) => {
        getAns(result.text);
        return result.text;
      },
      (error) => {
        getAns(error.text);
        return error.text;
      }
    );
  };

  render() {
    const { name, tel, email, message } = this.state;
    return (
      <section class="contact">
        <div className="job">
          <div className="container">
            <div className="job__text">
              <h3 className="job__text-main">Would you like to work with us?</h3>
              <p className="job__text-paragraph">
                 We have created a team of the best professionals, but we focus on
                 continuous development.
              </p>
            </div>
            <LinkRouter
              onClick={() => setTimeout(() => scrollToTop(), 100)}
              to="/Apply"
            >
              <a className="job__button" href="#">
                <div>Learn more</div>
              </a>
            </LinkRouter>
          </div>
        </div>

        
        <div className="contacts container">
          <form className="contacts__form">
            <h2>Contact us📲</h2>
            <input
              onChange={(e) => {
                this.handleChange(e, "name");
              }}
              value={name}
              type="text"
              placeholder="Name*"
            />
          
            <input
              onChange={(e) => {
                this.handleChange(e, "email");
              }}
              value={email}
              type="text"
              placeholder="E-mail address*"
            />
            <input
              onChange={(e) => {
                this.handleChange(e, "tel");
              }}
              value={tel}
              type="text"
              placeholder="Phone number*"
            />
            <textarea
              onChange={(e) => {
                this.handleChange(e, "message");
              }}
              value={message}
              rows="4"
              cols="50"
              placeholder="Message body"
            />
            <p style={{ fontSize: "12px", alignSelf: "start" }}>
              * - required fields
            </p>
            <div
              onClick={() => console.log("click")}
              className="contacts__form-button"
            >
                   <Recaptcha
                        ref={ ref => this.recaptcha = ref }
                        sitekey="6LemvBQbAAAAABLATQIth8kQKfHRpHOueqNS7ZY2"
                        onResolved={ this.onResolved }
          />
              <ButtonUI handleSend={this.handleBtn} />
         
            </div>
          </form>
          <Element name="contact">
            <div className="contacts__info">
            <div className="card__img-wrapper">
        <img src={kp1} className="card__img" alt="kp1" />
        </div>
              <h1>
                <span></span>
              </h1>
              <h2></h2>
              <p> </p>
              <div className="destination">
                
                <p>
                   <br />
                   <br />
                  
                </p>
              </div>
              <div className="info2">
                <strong> </strong>
                <span></span>
                <br />
                <strong> </strong>
                <span></span>
              </div>
            </div>
            
          </Element>
        </div>
      </section>
    );
  }
}
import React from "react";
import { Icon } from "@iconify/react";
import arrowDownAlt2 from "@iconify/icons-dashicons/arrow-down-alt2";
import "./Header.css";
import { Element } from "react-scroll";
import { Link } from "react-scroll";
import Typical from 'react-typical';

export default function Header(props) {
  return (
    <Element name="header">
      <header className="header">
        <div className="header__text">
          <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex content-justify-center flex-column">
          <h3 className="header__text-main">
                What We Do {''}
                  <Typical
                  loop={Infinity}
                  wrapper='b'
                  steps={[
                    'Full Stack Developers',
                    1000,
                    'Programming Languages ',
                    1000,
                    'CRM',
                    1000,
                    'Cyber Security',
                    1000,
                    'Frameworks',
                    1000

                  ]}
                  ></Typical>
            
          </h3>
          <span className="header__text-secondary">
            
          </span>

          <Link
            activeClass="active nodisplay"
            to="offer"
            spy={true}
            smooth={true}
            duration={500}
            offset={-window.innerHeight * 0.1}
          >
            
          </Link>
        </div>
        </div>
        <Link
          activeClass="active nodisplay"
          to="offer"
          spy={true}
          smooth={true}
          duration={500}
          offset={-props.vh * 0.1}
        >
          <Icon
            className="header__icon"
            icon={arrowDownAlt2}
            style={{ color: "#c4c4c4", fontSize: "53px" }}
          />
        </Link>
      </header>
    </Element>
  );
}
import React from "react";
import "./Realizations.css";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import { Element } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";
import { scrollToTop } from "../../Utils/Scroll&Animations";

export default function Realizations() {


  return (
    <Element name="realizations">
      <div className="realization container">
        <div data-aos="fade-right" className="realization__text">
          <h2 className="realization__text-main">CONSULTING</h2>
          <p className="realization__text-paragraph">
          Drive Strategic Vision, Thrive Amidst Disruption
          Power sustainable growth leveraging our industry expertise and contextual knowledge of your business.
          </p>
        </div>
        
        <div className="seeMore"></div>
      </div>
    </Element>
  );
}
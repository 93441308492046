import React from "react";
import "./About.css";

import { Element } from "react-scroll";

export default function About() {
  return (
    <>
      <Element name="about">
        <section className="about">
          <div data-aos="fade-right" className="about__text">
            <h2>What we do  </h2>
            <p className="about__paragraph">
            We are end-to-end solutions providing Software Development Company that allows users to get the workplace productivity on virtual, physical and connected environment thus ensuring better workforce management and increased productivity.

            </p>
            <p className="about__paragraph">
            With our new-gen technology solutions we ensure you to have a speedy and enhanced business mobilization
            Your Trusted, Reliable & Dependable Technology Partner through the entire product lifecycle.
            We deliver #digital success. 
            </p>
            <p className="about__paragraph">
              We are focused not only on cooperation with large companies
              construction, industrial or investor sectors, but also on the client
              Private. We guarantee short lead time and competitive
              prices due to long-term discounts among suppliers
              Materials.
            </p>
            <p className="about__paragraph">
              Our experienced and carefully selected staff ensures the highest
              quality of the services provided. We work on the best available
              tools, which guarantees a good and fast work done.
              We focus on continuous development!
            </p>
            <p className="about__paragraph">
            We are dedicated to help you build the application and technologies that meet your business aspirations. We know the latest versions and libraries and can integrate technologies to satisfy your expectations.
            Repletech works as your outsourced product development partner and provides end-to-end product development lifecycle management.
            We brainstorm, design, develop, test and maintain your product as your extended IT team. Our agile methodologies are ideally suitable for the iterative development and better product-market-fit.

            </p>
          </div>

          <div className="about__img"></div>
        </section>
        <section className="numbers">
          <ul className="numbers__list">
            <li
              className="numbers__item"
              data-aos="fade-up"
              data-aos-offset="200"
              ata-aos-duration="200"
              data-aos-delay="0"
            >
              <strong className="numbers__item-number">2021</strong>
              <span className="numbers__item-description">
              Year<br />
              Assumptions
              </span>
            </li>
            <li
              className="numbers__item"
              data-aos="fade-up"
              data-aos-offset="200"
              ata-aos-duration="200"
              data-aos-delay="300"
            >
              <strong className="numbers__item-number">7+</strong>
              <span className="numbers__item-description">
              Technology <br /> Units
              </span>
            </li>
            <li
              className="numbers__item"
              data-aos="fade-up"
              data-aos-offset="200"
              ata-aos-duration="200"
              data-aos-delay="600"
            >
              <strong className="numbers__item-number">2.5K+</strong>
              <span className="numbers__item-description">
              Made <br />
              Projects
              </span>
            </li>
            <li
              className="numbers__item"
              data-aos="fade-up"
              data-aos-offset="200"
              ata-aos-duration="200"
              data-aos-delay="900"
            >
              <strong className="numbers__item-number">1.5K+</strong>
              <span className="numbers__item-description">
               Satisfied <br />
               Clients
              </span>
            </li>
          </ul>
        </section>
      </Element>
    </>
  );
}